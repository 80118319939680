const carousel = document.querySelector('#carousel ul');
const slides = document.querySelectorAll('#carousel li');
const buttons = document.querySelectorAll('#carousel button');

const nextSlide = () => {
  buttons[currentSlide].classList.remove('bg-neutral-700');
  currentSlide = ++currentSlide % slides.length;
  buttons[currentSlide].classList.add('bg-neutral-700');

  carousel.scrollTo({
    left: currentSlide * carousel.clientWidth,
    behavior: 'smooth',
  });
}

let currentSlide = 0;
let slideInterval = setInterval(nextSlide, 5000);

buttons.forEach((button, index) => {
  button.addEventListener('click', () => {
    buttons[currentSlide].classList.remove('bg-neutral-700');
    currentSlide = index;
    buttons[currentSlide].classList.add('bg-neutral-700');

    carousel.scrollTo({
      left: currentSlide * carousel.clientWidth,
      behavior: 'smooth',
    });

    clearInterval(slideInterval);
    slideInterval = setInterval(nextSlide, 5000);
  });
});

carousel.addEventListener('scroll', () => {
  const slideIndex = Math.round(carousel.scrollLeft / carousel.clientWidth);
  if (slideIndex !== currentSlide) {
    buttons[currentSlide].classList.remove('bg-neutral-700');
    currentSlide = slideIndex;
    buttons[currentSlide].classList.add('bg-neutral-700');
  }

  clearInterval(slideInterval);
  slideInterval = setInterval(nextSlide, 5000);
});
